
import React from "react";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import Home from "../pages/Home";
import Guida from "../pages/Guida";
import Categoria from "../pages/Categoria";
import Attrazione from "../pages/Attrazione";
import Codificatore from "../pages/Codificatore";

const Stack = createStackNavigator();

export default function Navigator() {

    const linking = {
        config: {
            screens: {
                Home: "reserved/:data",
                Guida: "",
                Categoria: ":categoria",
                Attrazione: ":categoria/:attrazione",
                Codificatore: "codificatore",
            }
        },
    };

    return (
        <NavigationContainer linking={linking}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                }}
            >
                <Stack.Screen name="Home" component={Home} />
                <Stack.Screen name="Guida" component={Guida} />
                <Stack.Screen name="Categoria" component={Categoria} />
                <Stack.Screen name="Attrazione" component={Attrazione} />
                <Stack.Screen name="Codificatore" component={Codificatore} />
            </Stack.Navigator>
        </NavigationContainer>

    );
}