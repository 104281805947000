import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

function BreadCrumb({pages, onlyLast}) {

    if (onlyLast) {
        const index = pages.length - 2;
        const page = pages[index];
        return (
            <View style={styles.container}>
                <View style={styles.itemLine}>
                    <Text style={styles.item}>{'<'}</Text>
                    { page[1] && 
                        <TouchableOpacity onPress={() => page[1]()}>
                            <Text style={{...styles.item, ...styles.itemLink}}>
                                {page[0]}
                            </Text>
                        </TouchableOpacity>
                    }
                    { !page[1] && <Text style={styles.item}>{page[0]}</Text>}
                </View>
            </View>
        )
    }
    return (
        <View style={styles.container}>
            { pages.map((page, index) => {
                return (
                    <View style={styles.itemLine}>
                        {index > 0 && <Text style={styles.item}>{'>'}</Text>}
                        { page[1] && 
                            <TouchableOpacity onPress={() => page[1]()}>
                                <Text style={{...styles.item, ...styles.itemLink}}>
                                    {page[0]}
                                </Text>
                            </TouchableOpacity>
                        }
                        { !page[1] && <Text style={styles.item}>{page[0]}</Text>}
                    </View>
                )
            }) }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        display: 'flex',
        flexDirection: 'row',
    },
    itemLine: {
        display: 'flex',
        flexDirection: 'row',
    },
    itemLink: {
        textDecorationLine: 'underline'
    },
    item: {
        paddingHorizontal: 5,
        textTransform: 'uppercase',
        fontSize: 11,
        color: '#363636'
    },
});

export default BreadCrumb;