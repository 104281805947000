import { useEffect, useState } from "react";
import constants from "../constants.js";

const CATALOG_BASE_URL = constants.CATALOG_BASE_URL;
const URL_CONFIGURAZIONE = new URL(constants.CATALOG_CONFIGURAZIONE_QUERY, CATALOG_BASE_URL).href;
const AUTHORIZATION = constants.CATALOG_AUTHORIZATION;

const parseJSON = (resp) => (resp.json ? resp.json() : resp);

const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then((resp) => {
    throw resp;
  });
};

const parameterLabels = ["nome","cognome","nome_cognome","checkin","checkout","costo","telefono","mail"]

function GetConfigurazioni (titolo, parameters=[], mediaUrl=false) {

    const [error, setError] = useState(null);
    const [configurazioni, setConfigurazioni] = useState([]);

    useEffect(() => {
        const url = URL_CONFIGURAZIONE;
        fetch(url, {
            headers: {
                'Authorization': AUTHORIZATION,
            },
        }).then(checkStatus)
        .then(parseJSON)
        .then(({ data }) => setConfigurazioni(data))
        .catch((error) => setError(error));
    }, []);

    if (error) {
        return `An error occured: ${error.message}`;
    }

    if(configurazioni === null || configurazioni.length===0){
        return "";
    }

    let content = "";

    let title = titolo['titolo'] || titolo;
    if(title in Object.values(configurazioni)[1]){
        content = Object.values(configurazioni)[1][title];
    }

    if (!mediaUrl && parameters !== null && parameters.length > 0) {
        for (let i = 0; i < parameters.length; i++) { 
            content = content.replace("{{"+parameterLabels[i]+"}}", parameters[i]);
        }
    }

    if (mediaUrl) {
        if (content.data) {
            content = content = new URL(content.data.attributes.url, CATALOG_BASE_URL).href;
        } else {
            content = '';
        }
    }
    
    return content;
};
export default GetConfigurazioni;