// import { Text, Linking } from 'react-native';
import { default as OriginalMarkdown } from 'react-markdown';
// import { brown } from './colors';

export function Markdown(props) {
    return (
        <OriginalMarkdown components={{
            a(props) {
                const {href, children} = props;
                console.log(props);
                /* return <Text style={{color: brown}}
                            onPress={() => Linking.openURL(href)}>
                            {children}
                        </Text>; */
                return <a style={{color: 'black'}} href={href}>{children}</a>
            },
        }} {...props} />
    )
}