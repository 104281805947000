import { useMap } from 'react-leaflet'

export const RouteWay = ({ sourceCoords, destCoords }) => {

    const map = useMap();

    var L = require('leaflet');
    require('leaflet-routing-machine');

    // eslint-disable-next-line
    const routingControl = L.Routing.control({
        waypoints: [
          L.latLng( sourceCoords[0], sourceCoords[1] ),
          L.latLng( destCoords[0], destCoords[1] )
        ],
    }).addTo(map);

}