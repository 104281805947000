function getEnv(variable_name, default_value) {
    if (process.env[variable_name]) {
        return process.env[variable_name];
    }
    return default_value;
}

const constants = {
    CATALOG_BASE_URL: getEnv("REACT_APP_CATALOG_BASE_URL", "http://localhost:1337"),
    CATALOG_ATTRAZIONI_QUERY: getEnv("REACT_APP_CATALOG_ATTRAZIONI_QUERY", "api/attrazioni?populate=*&sort=Univoco&pagination[pageSize]={{pageSize}}"),
    CATALOG_CATEGORIE_QUERY: getEnv("REACT_APP_CATALOG_CATEGORIE_QUERY", "api/categorie?populate=*&sort=Univoco&pagination[pageSize]={{pageSize}}"),
    CATALOG_CONFIGURAZIONE_QUERY: getEnv("REACT_APP_CATALOG_CONFIGURAZIONE_QUERY", "api/configurazione?populate=*&sort=Univoco"),
    CATALOG_AUTHORIZATION: getEnv("REACT_APP_CATALOG_AUTHORIZATION", "Bearer ***"),
    CATALOG_QUERY_MAX_ITEMS: parseInt(("REACT_APP_CATALOG_QUERY_MAX_ITEMS", "100")) || 100,
    NAVIGATOR_USER_MAX_DISTANCE: parseInt(getEnv("REACT_APP_NAVIGATOR_USER_MAX_DISTANCE", "30")) || 30,
};

export default constants;
