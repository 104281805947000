import { useEffect, useState } from "react";
import React from 'react';
import constants from "../constants.js";
import { replaceTemplate } from "../utils/templateUtils.ts";

const CATALOG_BASE_URL = constants.CATALOG_BASE_URL;
const URL_ATTRAZIONI = new URL(constants.CATALOG_ATTRAZIONI_QUERY, CATALOG_BASE_URL).href;
const AUTHORIZATION = constants.CATALOG_AUTHORIZATION;

const parseJSON = (resp) => (resp.json ? resp.json() : resp);
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then((resp) => {
    throw resp;
  });
};

function FilterAttrazioni(category, page, parameter){
    const [error, setError] = useState(null);
    const [attrazioni, setAttrazioni] = useState([]);
  
    useEffect(() => {
      const url = replaceTemplate(
        URL_ATTRAZIONI,
        new Map([
            ["categoria", category],
            ["pageSize", constants.CATALOG_QUERY_MAX_ITEMS]
        ]));
      fetch(url, {
          headers: {
              'Authorization': AUTHORIZATION,
          },
      }).then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setAttrazioni(data))
      .catch((error) => setError(error));
    }, [category]);
  
    if (error) {
      return <div>An error occured: {error.message}</div>;
    }

    if(attrazioni.length===0){
        return;
    }
    if(page==="categoria"){
        let attrazioni_categoria=[]
        attrazioni.map(({ id, attributes }) => (
            attributes.Categorie.data.forEach((element) => {
                if(element.attributes.Univoco === category){
                    let url = '';
                    try {
                        url = CATALOG_BASE_URL+attributes.Copertina.data.attributes.url;
                    } catch (error) {
                        url = '';
                    }
                    attrazioni_categoria.push([attributes.Univoco, attributes.Nome, attributes.BackgroundColor, url]);
                }
            })
        ))

        if(attrazioni_categoria.length===0){
            return;
        }

        return attrazioni_categoria;
    }

    if(page === "attrazione"){
        for(let i = 0; i < attrazioni.length; i++){
            if(attrazioni[i].attributes.Univoco === category){
                return attrazioni[i].attributes[parameter];
            }
        }  
    }  

    if(page==="attrazione2"){
        let altre=[]
        attrazioni.map(({ id, attributes }) => (
            attributes.Categorie.data.forEach((element) => {
                if(element.attributes.Univoco === category && attributes.Univoco !== parameter){
                    altre.push([attributes.Univoco, attributes.Nome, attributes.DescrizioneBreve, attributes.Posizione]);
                }
            })
        ))
        return altre;
    }
};

export default FilterAttrazioni;