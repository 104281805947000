import React, { useState } from 'react';
import { Text, TextInput, TouchableOpacity, StyleSheet, Clipboard } from 'react-native';
import { encode } from 'base-64';
import MainTemplate from '../../templates/MainTemplate';

const Codificatore = (props) => {
  const [jsonData, setJsonData] = useState({});
  const [nome, setNome] = useState('');
  const [giornoDa, setGiornoDa] = useState('');
  const [giornoA, setGiornoA] = useState('');
  const [costo, setCosto] = useState('');
  const [colazione, setColazione] = useState('');

  const handleGenerateJson = () => {
    const data = {
      nome: nome,
      giornoDa: giornoDa,
      giornoA: giornoA,
      costo: costo,
      colazione: colazione,
    };
    setJsonData(data);
    const jsonString = JSON.stringify(jsonData, null, 2);
    const base64String = encode(encodeURIComponent(jsonString));
    Clipboard.setString("http://localhost:3000/reserved/"+base64String);
    alert('Link personalizzato copiato negli appunti!');
  };

  const handleCopyToClipboard = () => {
    const jsonString = JSON.stringify(jsonData, null, 2);
    const base64String = encode(jsonString);
    Clipboard.setString(base64String);
    alert('JSON codificato in Base64 e copiato negli appunti!');
  };

  return (
    <MainTemplate {...props}>
      <Text style={styles.title}>Generatore di link Orobie</Text>
      <TextInput
        style={styles.input}
        placeholder="Nome cliente"
        value={nome}
        onChangeText={text => setNome(text)}
      />
      <TextInput
        style={styles.input}
        placeholder="Data inizio"
        value={giornoDa}
        onChangeText={text => setGiornoDa(text)}
      />
      <TextInput
        style={styles.input}
        placeholder="Data fine"
        value={giornoA}
        onChangeText={text => setGiornoA(text)}
      />
      <TextInput
        style={styles.input}
        placeholder="Costo"
        value={costo}
        onChangeText={text => setCosto(text)}
      />
      <TextInput
        style={styles.input}
        placeholder="Colazione"
        value={colazione}
        onChangeText={text => setColazione(text)}
      />
      <TouchableOpacity style={styles.generateButton} onPress={handleGenerateJson}>
        <Text style={styles.buttonText}>Genera link personalizzato</Text>
      </TouchableOpacity>
      {false && jsonData && Object.keys(jsonData).length > 0 && (
        <>
          <Text style={styles.generatedJson}>JSON generato:</Text>
          <Text style={styles.jsonText}>{JSON.stringify(jsonData, null, 2)}</Text>
          <TouchableOpacity style={styles.copyButton} onPress={handleCopyToClipboard}>
            <Text style={styles.buttonText}>Copia JSON in Base64</Text>
          </TouchableOpacity>
        </>
      )}
    </MainTemplate>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  input: {
    height: 40,
    borderColor: '#cccccc',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  generateButton: {
    backgroundColor: '#007bff',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: 'center',
    marginBottom: 10,
  },
  copyButton: {
    backgroundColor: '#007bff',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 10,
  },
  buttonText: {
    color: '#ffffff',
    fontSize: 16,
  },
  generatedJson: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 20,
  },
  jsonText: {
    fontFamily: 'Courier',
    backgroundColor: '#f0f0f0',
    padding: 10,
    fontSize: 14,
    marginBottom: 10,
  },
});

export default Codificatore;