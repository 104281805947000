import {useEffect, useState} from 'react';
import {Dimensions, Platform} from 'react-native';

export function useIsMobile() {
    return Platform.OS === 'android' || Platform.OS === 'ios';
}

export function useIsPortrait() {
    const [orientation, setOrientation] = useState(true);

    useEffect(() => {
        const {height, width} = Dimensions.get('window')
        setOrientation(width < height);
        Dimensions.addEventListener('change', ({window:{width,height}})=>{
            setOrientation(width < height);
        })
  
    }, []);
  
    return orientation;
}