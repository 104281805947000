import { useEffect, useState } from "react";
import React from 'react';
import constants from "../constants.js";
import { replaceTemplate } from "../utils/templateUtils.ts";

const CATALOG_BASE_URL = constants.CATALOG_BASE_URL;
const URL_CATEGORIE = new URL(constants.CATALOG_CATEGORIE_QUERY, CATALOG_BASE_URL).href;
const AUTHORIZATION = constants.CATALOG_AUTHORIZATION;

const parseJSON = (resp) => (resp.json ? resp.json() : resp);
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then((resp) => {
    throw resp;
  });
};

function GetCategorie(props, page, parameter) {
  //const { navigation } = props;
  const [error, setError] = useState(null);
  const [categorie, setCategorie] = useState([]);

  useEffect(() => {
    const url = replaceTemplate(
      URL_CATEGORIE,
      new Map([
          ["pageSize", constants.CATALOG_QUERY_MAX_ITEMS]
      ]));
    fetch(url, {
      headers: {
        'Authorization': AUTHORIZATION,
      },
    }).then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setCategorie(data))
      .catch((error) => setError(error));
  }, []);

  if (error) {
    return <div>An error occured: {error.message}</div>;
  }
  if (page === "guida") {
    let categorie_render = []
    categorie.map(({ id, attributes }) => (
      categorie_render.push([attributes.Univoco, attributes.Nome, attributes.BackgroundColor, attributes.Copertina.data != null ? CATALOG_BASE_URL+attributes.Copertina.data.attributes.url : ''])
    ))
    return categorie_render;
  }
  if (page === "attrazione") {
    for (let i = 0; i < categorie.length; i++) {
      if (categorie[i].attributes.Univoco === parameter) {
        return [categorie[i].attributes.Nome, categorie[i].attributes.Descrizione, categorie[i].attributes.LinkKoomot];
      }
    }
  }
};

export default GetCategorie;