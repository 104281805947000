import React from 'react';
import { Pressable, Text, View, StyleSheet, Image, Linking, ImageBackground } from 'react-native';
import MainTemplate from '../../templates/MainTemplate';
import FilterAttrazioni from '../../api/GetAttrazioni';
import GetCategorie from '../../api/GetCategorie';
import GetConfigurazioni from '../../api/GetConfigurazioni';
import BreadCrumb from '../../components/BreadCrumb';

const Categoria = (props) => {

  const { route, navigation } = props

  const Attrazione = ({id, nome, color, url}) => {
    return (
      <Pressable onPress={() => navigation.replace("Attrazione", {categoria: route.params.categoria, attrazione: id})}>
        <ImageBackground style={{...styles.attrazioneBox, backgroundColor: color, overflow: 'hidden'}} source={{uri: url}}>
          <View style={{...styles.textAreaView, ...styles.textAreaViewBackground}}>
            <Image style={styles.buttonIcon} source={{uri: '/icons8-touch-50.png'}} />
            <Text style={styles.attrazioneText}>{nome}</Text>
          </View>
        </ImageBackground>
      </Pressable>
    )
  }

  const attrazioni = FilterAttrazioni(route.params.categoria, "categoria", "");
  let attrazioni_render = [];
  if(attrazioni){
    attrazioni.forEach((element) => {
      attrazioni_render.push(<Attrazione key={element[0]} id={element[0]} nome={element[1]} color={element[2]} url={element[3]} />);
    })
  }

  const parametriCategoria = GetCategorie(props, "attrazione", route.params.categoria);
  const titoloCategoria = parametriCategoria && parametriCategoria.length > 0 ? parametriCategoria[0] : '';
  const sottotitoloCategoria = parametriCategoria && parametriCategoria.length > 0 ? parametriCategoria[1] : '';
  const linkKoomot = parametriCategoria && parametriCategoria.length > 0 ? parametriCategoria[2] : '';

  const testoKoomot = GetConfigurazioni("TestoKoomotCategoria");
  const mediaKoomot = GetConfigurazioni("SfondoKoomotCategoria", [], true);

  document.title = titoloCategoria+' - Guida Interattiva - Orobie Alps Resort';
  return (
    <MainTemplate {...props} breadcrumb={
      <BreadCrumb pages={[
        ['Home', () => {navigation.replace("Guida")}],
        [titoloCategoria, '']
      ]} />
    }>
      
      <Text style={styles.title}>{titoloCategoria}</Text>
      <Text style={styles.subtitle}>{sottotitoloCategoria}</Text>
      {attrazioni_render}
      { linkKoomot && (
        <Pressable onPress={() => Linking.openURL(linkKoomot)}>
          <ImageBackground style={{...styles.attrazioneBox, backgroundColor: '#6aa127', overflow: 'hidden'}} source={{uri: mediaKoomot}}>
            <View style={styles.koomotLogoBox}>
              <Image style={styles.koomotLogo} source={{uri: '/logo_komoot_primary_RGB_v2-1.png'}} />
            </View>
            <View style={styles.textAreaView}>
              <Text style={styles.attrazioneText}>{testoKoomot}</Text>
            </View>
          </ImageBackground>
        </Pressable>
      )}
    </MainTemplate>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5,
    //textTransform: 'capitalize'
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 20,
  },
  attrazioneBox: {
    marginVertical: 10,
    paddingTop: 25,
    borderRadius: 10,
    minHeight: 180,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textAreaView: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 30,
    paddingVertical: 10,
    backgroundColor: '#777777aa',
  },
  textAreaViewBackground: {
    backgroundColor: '#777777aa',
  },
  attrazioneText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18
  },
  koomotLogoBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 30,
  },
  koomotLogo: {
    width: 185,
    height: 55,
    objectFit: 'scale-down'
  },
  buttonIcon: {
    width: 25,
    height:25,
    marginRight: 10
  },
});

export default Categoria;