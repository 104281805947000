import { useState } from 'react';
import { StyleSheet, Text, View, Pressable, Image } from 'react-native';

import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import { useMap } from 'react-leaflet/hooks'

import { 
  toLatLon, headingDistanceTo
} from 'geolocation-utils'

import { Icon } from "leaflet";
import { RouteWay } from './RouteWay';
import { Linking } from 'react-native-web';
import constants from '../constants';
import { useIsMobile, useIsPortrait } from '../utils/platformUtils';

function MapDraggingManager() {
  const map = useMap();
  const isMobile = useIsMobile();
  const isPortrait = useIsPortrait()
  if (isMobile || isPortrait) {
    map.dragging.disable();
  } else {
    map.dragging.enable();
  }
  return null;
}

function Map({ route, navigation, subjectMarker, otherMarkers, polygon, koomot }) {

    const [routering, setRoutering] = useState('');
    const [coordinatesItinerary, setCoordinatesItinerary] = useState('');

    const currentIcon = new Icon({
      iconUrl: "/i8-marker-current.png",
      iconSize: [48, 48]
    });

    const othersIcon = new Icon({
      iconUrl: "/i8-marker-other.png",
      iconSize: [48, 48]
    });

    const resortIcon = new Icon({
      iconUrl: "/i8-marker-resort.png",
      iconSize: [48, 48]
    });

    function buttonPress() {
      navigator.geolocation.getCurrentPosition(position => {
        if (headingDistanceTo(
          toLatLon([position.coords.latitude, position.coords.longitude]),
          toLatLon([45.95566652994623, 9.755162100522398])
        ) < constants.NAVIGATOR_USER_MAX_DISTANCE*1000) { // If distance from hotel is < 30 km
          setCoordinatesItinerary([position.coords.latitude, position.coords.longitude]);
        } else {
          setCoordinatesItinerary([45.95566652994623, 9.755162100522398]);
        }
      });
      setRoutering(true);
    }

    let centerLat=45.95566652994623;
    let centerLon=9.755162100522398;
    
    if(subjectMarker){
      return (
        <MapContainer style={styles.mapbox}
          bounds={[[centerLat, centerLon],subjectMarker.point["geometry"]["coordinates"].slice().reverse()]}
          boundsOptions={{padding: [30,30]}}
          scrollWheelZoom={false}>
          <MapDraggingManager />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[45.95566652994623, 9.755162100522398]} icon={resortIcon}>
            <Popup>
            <View style={{width: 170}}>
                <Text style={styles.markerTitle}>Orobie Alps Resort</Text>
                <Text style={styles.markerSubtitle}>Il punto da cui dovrai partire</Text>
              </View>
            </Popup>
          </Marker>
          { subjectMarker && 
            <Marker position={subjectMarker.point["geometry"]["coordinates"].slice().reverse()} icon={currentIcon}>
              <Popup>
              <View style={{width: 170}}>
                  <Text style={styles.markerTitle}>{subjectMarker.nome}</Text>
                  <Text style={styles.markerSubtitle}>{subjectMarker.descrizione}</Text>
                </View>
                { !koomot && !routering && 
                  <Pressable style={styles.markerPressable}
                    onPress={() => buttonPress()}>
                    <Image style={styles.buttonIcon} source={{uri: '/icons8-touch-50.png'}} />
                      Navigatore
                  </Pressable>
                }
                { koomot &&
                  <Pressable style={styles.markerPressableKoomot}
                  onPress={() => Linking.openURL(koomot)}>
                    <Image style={styles.buttonIcon} source={{uri: '/koomot.png'}} />
                      Escursione
                  </Pressable>
                }
              </Popup>
            </Marker>
          }
          { otherMarkers && otherMarkers.length > 0 && otherMarkers
                  .filter((marker) => marker.point)
                  .map(otherMarker => (
            <Marker position={otherMarker.point["geometry"]["coordinates"].slice().reverse()} icon={othersIcon}>
              <Popup>
                <View style={{width: 170}}>
                  <Text style={styles.markerTitle}>{otherMarker.nome}</Text>
                  <Text style={styles.markerSubtitle}>{otherMarker.descrizione}</Text>
                  <Pressable style={styles.markerPressable}
                    onPress={() => navigation.replace("Attrazione", {categoria: route.params.categoria, attrazione: otherMarker.id})}>
                    <Image style={styles.buttonIcon} source={{uri: '/icons8-touch-50.png'}} />
                      Maggiori dettagli
                  </Pressable>
                </View>
              </Popup>
            </Marker>
          ))
          }
          { polygon &&
            <Polyline pathOptions={{color: 'purple'}} positions={polygon.polygon["geometry"]["coordinates"].map(x=>x.slice().reverse())} />
          }
          { routering &&
            <RouteWay sourceCoords={coordinatesItinerary} destCoords={subjectMarker.point["geometry"]["coordinates"].slice().reverse()} />
          }
        </MapContainer>
      );
    }
    else{
      return (
        <Text style={styles.markerTitle}>Caricamento in corso...</Text>
      );

    }
}

const styles = StyleSheet.create({
  mapbox: {
    width: '100%',
    height: '100%'
  },
  markerTitle: {
    fontWeight: 'bold'
  },
  markerSubtitle: {

  },
  markerPressable: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginVertical: 7,
    borderRadius: 5,
    backgroundColor: '#3e8afc',
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
  },
  markerPressableKoomot: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginVertical: 7,
    borderRadius: 5,
    backgroundColor: '#6AA127',
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
  },
  buttonIcon: {
    width: 20,
    height:20,
    marginRight: 10
  },
})

export default Map;