export function replaceTemplate(text: any, params: Map<string, string>) {
    if (!(typeof text === 'string')) {
        return text;
    }

    let result: string = text;

    params.forEach( function(value: string, key: string) {
        result = result.replace("{{"+key+"}}", value);
        result = result.replace("{"+key+"}", value);
    })
    
    return result;
}