import React from 'react';
import { Pressable, View, Text, Image, StyleSheet, ScrollView, Linking } from 'react-native';

const MainTemplate = ({ navigation, children, noBorders, breadcrumb }) => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Pressable style={styles.headerPressable} onPress={() => navigation.replace("Guida")}>
          <Image style={styles.headerLogo} source={{uri: '/logo_vettoriale.svg'}} />
        </Pressable>
      </View>
      <ScrollView 
        centerContent={true}
        style={styles.content} 
        contentContainerStyle={styles.containerContent}>
          <View style={noBorders?styles.containerContentView:styles.containerContentViewPadding}>
            <View style={noBorders?styles.containerBreadcrumb:styles.containerBreadcrumbPadding}>
              {breadcrumb}
            </View>
            {children}
          </View>
      </ScrollView>
      <View style={styles.footer}>
        <Text style={styles.footerText}>Via Monica, 70 - 24010 Roncobello BG - P.IVA 01627440165</Text>
        <Text style={styles.footerText}>© {new Date().getFullYear()} Orobie Alps Resort S.N.C. - All Rights Reserved.</Text>
        <Pressable style={styles.footerDiv} onPress={() => Linking.openURL("https://nards.it")}>
          <Image style={styles.footerLogo} source={{uri: 'https://nards.it/logotipo_mini.png'}} /> <Text style={styles.footerCredit}>Developed by Nards IT</Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  header: {
    backgroundColor: '#7d5a4e',
    paddingVertical: 10,
    paddingHorizontal: 20,
    height: 80,
  },
  headerPressable: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  headerLogo:{
    aspectRatio: 764.5813/192.05333,
    width: 200,
    marginHorizontal: 30,
    marginVertical: 5,
  },
  headerText: {
    fontSize: 28,
    fontWeight: '100',
    color: 'white',
  },
  content: {
    height: 1,
    width: '100%',
  },
  containerContent: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    width: '100%',
  },
  containerContentViewPadding: {
    padding: 40,
    flex: 1,
    width: '100%',
    maxWidth: 700,
  },
  containerContentView: {
    flex: 1,
    width: '100%',
  },
  containerBreadcrumb: {
    paddingLeft: 40,
  },
  containerBreadcrumbPadding: {
    marginTop: -30,
    marginBottom: 10,
  },
  footer: {
    backgroundColor: '#f0f0f0',
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerText: {
    fontSize: 9,
    paddingVertical: 1,
  },
  footerLogo: {
    objectFit: 'contain',
    width: '60px',
    height: '15px'
  },
  footerDiv: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px'
  },
  footerCredit: {
    fontSize: 9,
    paddingVertical: 1,
    paddingTop: '1px',
    paddingLeft: '8px'
  }
});

export default MainTemplate;