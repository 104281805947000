import React from 'react';
import { Pressable, Text, View, StyleSheet, Linking, Image, ImageBackground } from 'react-native';
import MainTemplate from '../../templates/MainTemplate';
import GetConfigurazioni from '../../api/GetConfigurazioni';
import GetCategorie from '../../api/GetCategorie';


const Guida = (props) => {

  const { navigation } = props;

  const Categoria = ({id, nome, color, url}) => {
    return (
      <Pressable onPress={() => navigation.replace("Categoria", {categoria: id})}>
        <ImageBackground style={{...styles.categoriaBox, backgroundColor: color, overflow: 'hidden'}} source={{uri: url}}>
          <View style={styles.textAreaView}>
            <Image style={styles.buttonIcon} source={{uri: '/icons8-touch-50.png'}} />
            <Text style={styles.categoriaText}>{nome}</Text>
          </View>
        </ImageBackground>
      </Pressable>
    )
  }

  const categorie = GetCategorie(navigation, "guida", "");
  let categorie_render = [];
  if(categorie.length > 0){
    categorie.forEach((element) => {
      categorie_render.push(<Categoria key={element[0]} id={element[0]} nome={element[1]} color={element[2]} url={element[3]}/>);
      
    })
  }

  const testoFaq = GetConfigurazioni("TestoFAQ");
  const reindirizzamentoFAQ = GetConfigurazioni("ReindirizzamentoFAQ");
  const mediaFaq = GetConfigurazioni("SfondoFAQ", [], true);

  return (
    <MainTemplate {...props}>
      <Text style={styles.title}><GetConfigurazioni titolo="TitoloGuida"/></Text>
      <Text style={styles.subtitle}><GetConfigurazioni titolo="DescrizioneGuida"/></Text>
      {categorie_render}
    </MainTemplate>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 20,
  },
  categoriaBox: {
    marginVertical: 10,
    paddingTop: 25,
    borderRadius: 10,
    minHeight: 180,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textAreaView: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 30,
    paddingVertical: 10,
    backgroundColor: '#777777aa',
  },
  categoriaText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
  },
  buttonIcon: {
    width: 25,
    height:25,
    marginRight: 10
  },
});

export default Guida;